import {
  chatMailAppServerAxios,
  chatMailGroupAxios,
  chatMailStaticAxios,
  serverAxios,
} from "./config";

//For Web
export const GET = async (endPoint) => await serverAxios.get(endPoint);
export const POST = async (endPoint, requestData = {}) =>
  await serverAxios.post(endPoint, requestData);

//For Ad Campaign
export const CHAT_MAIL_APP_GET = async (endPoint) =>
  await chatMailAppServerAxios.get(endPoint);
export const CHAT_MAIL_APP_POST = async (endPoint, requestData = {}) =>
  await chatMailAppServerAxios.post(endPoint, requestData);

//For Group
export const GROUP_API_GET = async (endPoint) =>
  await chatMailGroupAxios.get(endPoint);
export const GROUP_API_POST = async (endPoint, requestData = {}) =>
  await chatMailGroupAxios.post(endPoint, requestData);

//Static page
export const STATIC_PAGE_GET = async (endPoint) =>
  await chatMailStaticAxios.get(endPoint);

// export const GET = async (endPoint) => {
//       try {
//             return await serverAxios.get(endPoint)
//       } catch (e) {
//             console.log(e);
//             return e;
//       }
// }
// export const POST = async (endPoint, requestData = {}) => {
//       try {
//             return await serverAxios.post(endPoint, requestData)
//       } catch (e) {
//             console.log(e);
//             return e;
//       }
// }
